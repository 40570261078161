
























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import configProvider from "@common/services/configProvider";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";

export default Vue.extend({
  components: { GlobalErrorSnackBar },
  data() {
    return {
      content: "Activating user...",
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      logoUrl: configProvider.get("LOGO_URL"),
      data: {},
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;
    try {
      const item = await coreApiClient.callRaw(
        "auth",
        "activeUser",
        {
          secret: this.$route.query.secret
        }
      );
      if (item) {
        this.content = "Account activated successfully.";
        setTimeout(() => {
          this.$router.replace("/login");
        }, 2000);
      } else {
        this.content = "Account activation failed.";
      }
    } catch (err) {
      this.content = "Account activation failed.";
    } finally {
      this.loading = false;
    }
  },
});
